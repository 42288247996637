import InvoiceDetailCustomerCard from './invoice-detail-customer-card'
import InvoiceDetailCard from './invoice-detail-card'
import { ExtendedInvoice } from '../../../../models'
import InvoiceDetailPaymentCard from './invoice-detail-payment-card'
import { useAuthTenant } from '../../../auth/store/auth-store'

interface Props {
  invoice: ExtendedInvoice;
}

export default function InvoiceDetailSideContent({ invoice }: Props) {
  const tenant = useAuthTenant()

  const tenantPaymentFeature = tenant.externalServices.paymentGateway.enabled

  return (
    <div className="w-3/12">
      <div className="w-full flex flex-col pr-2 gap-4">

        <InvoiceDetailCard
          invoice={invoice}
        />

        {tenantPaymentFeature && invoice.payments.map((payment) => (
          <InvoiceDetailPaymentCard key={payment.id} payment={payment} />
        ))}

        <InvoiceDetailCustomerCard customer={invoice.customer} paymentAccount={invoice.paymentAccount} />
      </div>
    </div>

  )
}
