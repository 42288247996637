import {
  Menu, Button, Card, Text, Title,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { BiDownload, BiMoney } from 'react-icons/bi'
import { FaChevronDown } from 'react-icons/fa'
import { TbRefresh } from 'react-icons/tb'
import { MdOutlineDiscount } from 'react-icons/md'
import { useMemo } from 'react'
import { useDowloadInvoiceDocument } from '../../queries'
import { ExtendedInvoice } from '../../../../models'
import { formatPlanNames } from '../../../../shared/utils/helpers/format-plan-names'
import InvoiceSynchronizeParams from '../../../../interfaces/invoice/invoice-synchronize-params'
import { BillingCycleStatus, ExternalProcessorSyncBillingCycleStatus } from '../../enum'
import { useAuthTenant } from '../../../auth/store/auth-store'
import { useDowloadPaymentFile } from '../../../payment/queries'
import { getInvoicePayment } from '../../../payment/utils/get-invoice-payment'
import PaymentFile from '../../../payment/enum/payment-file'
import { isPaymentFileAvailable } from '../../../payment/utils/is-payment-file-available'

interface Props {
  invoice: ExtendedInvoice
  onManageItems?: () => void;
  onSynchronize?: (keys: string[], synchronizeParams: InvoiceSynchronizeParams) => void;
  onSuccess?: () => void;
}

export default function InvoiceDetailHeader({
  invoice, onSynchronize, onSuccess, onManageItems,
}: Props) {
  const { t, i18n } = useTranslation()

  const tenant = useAuthTenant()

  const tenantPaymentFeature = tenant.externalServices.paymentGateway.enabled

  const latestPayment = useMemo(() => getInvoicePayment(invoice.payments), [invoice.payments])

  const formatImportantDates = () => {
    const endDateFormatted = new Date(invoice.endDate).toLocaleDateString(i18n.language, { timeZone: 'UTC' })
    return `${t('invoice:end-date-natural-language', { endDate: endDateFormatted })}`
  }

  const formatInvoicePlanNames = () => formatPlanNames(invoice.plans?.map((plan) => plan.name)) || `${t('invoice:invoice')}`

  const isInvoiceSync = () => (invoice.externalProcessorSyncStatus === ExternalProcessorSyncBillingCycleStatus.NotInitiated
    || invoice.externalProcessorSyncStatus === ExternalProcessorSyncBillingCycleStatus.Failed) && invoice.status === BillingCycleStatus.Closed && !invoice.isLocked

  const { mutate, isLoading: isDownloadingInvoiceFile } = useDowloadInvoiceDocument()
  const { mutate: mutateDownloadPaymentFile, isLoading: isDownloadingPaymentFile } = useDowloadPaymentFile()

  const handlePaymentDownloadFile = () => {
    if (latestPayment) {
      mutateDownloadPaymentFile({ id: latestPayment.id, paymentFile: PaymentFile.BankSlip })
    }
  }

  return (
    <Card padding="lg" radius="md" className="bg-primary-surface-subtle gap-6 px-6 py-6">
      <div className="flex flex-col gap-2">
        <div className="border w-fit border-primary-border-default rounded-full px-4 py-1">
          <Text size="xs" fw={600} className="text-primary-text-label">
            {invoice.id}
          </Text>
        </div>
        <Title order={4} fw={600} className="text-gray-text-title">
          {formatInvoicePlanNames()}
        </Title>
        <Text size="sm" fw={500} className="text-gray-text-body">
          {formatImportantDates()}
        </Text>
      </div>
      <Menu>
        <Menu.Target>
          <Button
            leftSection={<FaChevronDown />}
            size="sm"
            variant="outline"
            className="w-fit"
            loading={isDownloadingPaymentFile || isDownloadingInvoiceFile}
          >
            {t('invoice:quick-actions')}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            onClick={() => mutate(invoice.id)}
            leftSection={<BiDownload size={18} />}
          >
            {t('invoice:download')}
          </Menu.Item>
          {
            !invoice.isLocked && (
              <Menu.Item
                onClick={onManageItems}
                leftSection={<MdOutlineDiscount size={18} />}
              >
                {t('invoice:manage')}
              </Menu.Item>
            )
          }
          {
            onSynchronize && isInvoiceSync() && (
              <Menu.Item
                onClick={() => onSynchronize([invoice.id], { onSuccess: onSuccess || (() => { }) })}
                leftSection={<TbRefresh size={18} />}
              >
                {t('invoice:synchronize')}
              </Menu.Item>
            )
          }
          {
            tenantPaymentFeature && isPaymentFileAvailable(latestPayment) && (
              <Menu.Item
                onClick={handlePaymentDownloadFile}
                leftSection={<BiMoney size={18} />}
                disabled={!latestPayment}
              >
                {t('invoice:download-file')}
              </Menu.Item>
            )
          }
        </Menu.Dropdown>
      </Menu>
    </Card>
  )
}
