import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ScrollArea } from '@mantine/core'
import { useInvoiceQuery } from '../../queries'
import InvoiceDetailHeader from './invoice-detail-header'
import InvoicePlansDetail from './plan-detail/invoice-plans-detail'
import FetchInvoiceSkeleton from '../general-component/fetch-invoice-skeleton'
import ManageInvoiceAdditionalItemsModal from './additional-item/manage-invoice-additional-items-modal'
import InvoiceDetailSideContent from './invoice-detail-side-content'
import { extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems } from '../../utils/upsert-invoice-utils'
import InvoiceAdditionalItemsDetail from './additional-item/invoice-additional-items-detail'
import { useAuthTenant } from '../../../auth/store/auth-store'
import useInvoiceSynchronizeModal from '../modal-actions/invoice-synchronize-modal'
import InvoiceSynchronizeParams from '../../../../interfaces/invoice/invoice-synchronize-params'
import { ExternalProcessorSyncBillingCycleStatus } from '../../enum'

export default function InvoiceDetailContent() {
  const { id } = useParams()
  const tenant = useAuthTenant()
  const synchronizeModal = useInvoiceSynchronizeModal()

  const { data: invoice, isLoading: isQueryLoading, refetch } = useInvoiceQuery(id)
  const synchronizeInvoices = (invoiceIds: string[], invoiceSynchronizeParams: InvoiceSynchronizeParams) => {
    synchronizeModal(invoiceIds, invoiceSynchronizeParams)
  }

  const onSuccessSynchronize = () => {
    if (invoice) {
      invoice.isLocked = true
      invoice.externalProcessorSyncStatus = ExternalProcessorSyncBillingCycleStatus.Pending
      refetch()
    }
  }

  const [isManageInvoiceModalOpened, setIsManageInvoiceModalOpened] = useState(false)

  if (isQueryLoading || !invoice) {
    return <FetchInvoiceSkeleton />
  }

  const upsertedAdditionalItems = extendedInvoiceAdditionalItemsToUpsertInvoiceAdditionalItems(invoice.additionalItems)

  const handleOpenManageInvoiceModal = () => {
    setIsManageInvoiceModalOpened(true)
  }

  return (
    <ScrollArea.Autosize className="h-full" offsetScrollbars>
      <ManageInvoiceAdditionalItemsModal
        invoiceId={invoice.id}
        opened={isManageInvoiceModalOpened}
        onClose={() => setIsManageInvoiceModalOpened(false)}
        additionalItems={upsertedAdditionalItems}
      />

      <InvoiceDetailHeader
        invoice={invoice}
        onManageItems={handleOpenManageInvoiceModal}
        onSynchronize={tenant.externalServices.omie ? synchronizeInvoices : undefined}
        onSuccess={tenant.externalServices.omie ? onSuccessSynchronize : undefined}
      />

      <div className="flex w-full mt-6">
        <div className="w-9/12 mr-4">
          <div>
            <InvoicePlansDetail plans={invoice.plans} />
            <InvoiceAdditionalItemsDetail additionalItems={upsertedAdditionalItems} />
          </div>
        </div>

        <InvoiceDetailSideContent
          invoice={invoice}
        />
      </div>
    </ScrollArea.Autosize>

  )
}
