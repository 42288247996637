import i18n from '../../../config/i18n'
import { api } from '../../../libs'
import fileHelper from '../../../shared/utils/helpers/file-helper'
import GetPaymentFileRequest from '../../../libs/api/requests/payments/get-payment-file-request'

const downloadPaymentFile = async ({ id, paymentFile }: GetPaymentFileRequest) => {
  const getInvoiceDocumentResponse = await api.payments.getPaymentFile({ id, paymentFile })

  const fileName = `${i18n.t('payment')}_${id}`

  fileHelper.savePdfFile(getInvoiceDocumentResponse?.data, fileName)
}

const PaymentsService = {
  downloadPaymentFile,
}

export default PaymentsService
